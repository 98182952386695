import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import gsap from 'gsap';
import { motion, AnimatePresence } from 'framer-motion';
import Cursor from '../../Cursor';
import BlobVideo from '../../../video/bloob.mp4';
import { useRouteAnimate } from '../../../context/RouteAnimationContext';

const Layout = ({ children, props }) => {
  const [isFrontPage, setIsFrontPage] = useState(
    props.uri === '/' ? true : false
  );
  const [scrollPosition, setScrollPosition] = useState(0);
  const { isAnimating, updateIsAnimatingState } = useRouteAnimate();
  const blobContainerRef = useRef(null);

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (props && props.uri === '/') {
      setIsFrontPage(true);
    } else {
      setIsFrontPage(false);
    }
  }, [props]);

  useEffect(() => {
    const blobContainer = blobContainerRef.current;

    if (blobContainer) {
      gsap.set(blobContainer, { opacity: 0, scale: 0.8 });
      gsap.to(blobContainer, {
        opacity: 1,
        scale: 1,
        duration: 1.5,
        ease: 'power3.out',
        delay: 0.5,
      });
    }
  }, []);

  //   useEffect(() => {
  //     const blobContainer = blobContainerRef.current;

  //     if (blobContainer) {
  //       gsap.to(blobContainer, {
  //         opacity: 1 - scrollPosition * 0.001,
  //         duration: 0.5,
  //       });
  //     }
  //   }, [scrollPosition]);

  const onAnimationComplete = () => {
    updateIsAnimatingState(!isAnimating);
  };

  return (
    <>
      <AnimatePresence
        exitBeforeEnter="exitBeforeEnter"
        mode="wait"
        initial={false}
        style={{ position: 'relative' }}
      >
        <motion.div
          key={props.path}
          initial={{
            y: -120,
          }}
          animate={{
            y: 0,
          }}
          exit={{
            y: -120,
          }}
          onAnimationComplete={onAnimationComplete}
          transition={{
            duration: 0.6,
          }}
        >
          <motion.div
            key={props.path}
            initial={{
              y: 120,
            }}
            animate={{
              y: '-120vh',
            }}
            exit={{
              y: 120,
            }}
            transition={{
              duration: 0.6,
            }}
            style={{
              width: '100vw',
              height: '120vh',
              backgroundColor: '#f3f3f3',
              position: 'absolute',
              top: '0',
              zIndex: 522,
            }}
          />
          <div className="position-relative overflow-hidden h-100">
            <BlobContainer
              className="BlobContainerRef"
              ref={blobContainerRef}
              style={
                !isFrontPage ? { transform: 'translate(30%, -150px)' } : {}
              }
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: `
                  <video
                      loop
                      muted
                      autoPlay
                      playsInline
                      src="${BlobVideo}"
                  />,
              `,
                }}
              />
            </BlobContainer>
            <div className="position-relative">{children}</div>
          </div>
        </motion.div>
      </AnimatePresence>
      <Cursor />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

const BlobContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  overflow: hidden;

  video {
    position: absolute;
    top: -50px;
    left: 50%;
    z-index: -1;
    width: 100vw;
    transform: translate(-50%);

    @media (max-width: 768px) {
      top: 0px;
      right: -50px;
      z-index: -1;
      width: 200vw;
    }
  }
`;
