import React, { useEffect,  useState } from 'react';
import MobileDetect from 'mobile-detect';

const useMobileDetection = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const md = new MobileDetect(window.navigator.userAgent);
    setIsMobile(md.mobile());
  }, []);

  return isMobile;
};

export default useMobileDetection;