exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-archive-jsx": () => import("./../../../src/templates/archive.jsx" /* webpackChunkName: "component---src-templates-archive-jsx" */),
  "component---src-templates-archive-work-jsx": () => import("./../../../src/templates/archive-work.jsx" /* webpackChunkName: "component---src-templates-archive-work-jsx" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-category-work-jsx": () => import("./../../../src/templates/category-work.jsx" /* webpackChunkName: "component---src-templates-category-work-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-single-jsx": () => import("./../../../src/templates/single.jsx" /* webpackChunkName: "component---src-templates-single-jsx" */),
  "component---src-templates-single-work-jsx": () => import("./../../../src/templates/single-work.jsx" /* webpackChunkName: "component---src-templates-single-work-jsx" */),
  "component---src-templates-template-about-jsx": () => import("./../../../src/templates/template-about.jsx" /* webpackChunkName: "component---src-templates-template-about-jsx" */),
  "component---src-templates-template-contact-jsx": () => import("./../../../src/templates/template-contact.jsx" /* webpackChunkName: "component---src-templates-template-contact-jsx" */),
  "component---src-templates-template-services-jsx": () => import("./../../../src/templates/template-services.jsx" /* webpackChunkName: "component---src-templates-template-services-jsx" */)
}

