import TweenMax from 'gsap';

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import EventEmitter from '../services/EventEmitter';
import { lerp, getMousePos } from '../utils';
import useMobileDetection from '../components/useMobileDetection';

const Cursor = () => {
  const [isVisible, setIsVisible] = useState(false);
  const isMobile = useMobileDetection();
  const cursor = useRef();
  const cursorInner = useRef();

  useEffect(() => {
    let hover = false;
    let bounds;
    cursor.current
      ? (bounds = cursor?.current?.getBoundingClientRect())
      : (bounds = 0);

    let mousepos;
    const cursorSettings = { duration: 0.2, ease: 'Power3.easeOut' };

    let cursorTimeline;
    let cursorEnterTimeLine;
    let cursorLeaveTimeLine;

    const styles = {
      x: 0,
      y: 0,
      scale: 1,
      opacity: 1,
    };

    const attributesCursor = {
      width: 12,
      height: 12,
    };

    const attributesCursorRectangle = {
      width: 10,
      height: 10,
      rx: 5,
    };

    const bindEvents = function () {
      EventEmitter.on('button:enter', enter);
      EventEmitter.on('button:leave', leave);
      EventEmitter.on('button:animate', animate);

      window.addEventListener('mousemove', updateMousePosition);
      window.addEventListener('mousemove', mouseMoveHandler);
    };

    const unBindEvents = function () {
      EventEmitter.off('button:enter', enter);
      EventEmitter.off('button:leave', leave);
      EventEmitter.off('button:animate', animate);

      window.removeEventListener('mousemove', updateMousePosition);
      window.removeEventListener('mousemove', mouseMoveHandler);
    };

    const updateMousePosition = function (e) {
      setIsVisible(!isVisible);
      mousepos = getMousePos(e);
      cursor.current
        ? (bounds = cursor?.current?.getBoundingClientRect())
        : (bounds = 0);
    };

    const mouseMoveHandler = function (e) {
      if (!hover) {
        styles.x = mousepos.x - bounds.width / 2;
        styles.y = mousepos.y - bounds.height / 2;
      }

      if (cursorTimeline) {
        cursorTimeline.kill();
      }

      cursorTimeline = TweenMax.timeline();
      cursorTimeline
        .add('cursor')
        .to(
          cursor.current,
          { ...cursorSettings, ...styles, attr: { ...attributesCursor } },
          'cursor'
        )
        .to(
          cursorInner.current,
          { ...cursorSettings, attr: { ...attributesCursorRectangle } },
          'cursor'
        );
    };

    const enter = function (payload) {
      hover = true;
      window.removeEventListener('mousemove', mouseMoveHandler);

      cursorSettings.duration = 0.5;
      const styles = {};

      const attributesCursor = {};
      const attributesCursorRectangle = {};

      styles.width = payload.rect.width;
      styles.height = payload.rect.height;

      attributesCursor.width = payload.rect.width;
      attributesCursor.height = payload.rect.height;

      attributesCursorRectangle.width = attributesCursor.width - 2;
      attributesCursorRectangle.height = attributesCursor.height - 2;
      attributesCursorRectangle.rx = attributesCursorRectangle.height / 2;
      attributesCursorRectangle['fill-opacity'] = 0;

      if (cursorTimeline) {
        cursorTimeline.kill();
      }

      cursorEnterTimeLine = TweenMax.timeline();
      cursorEnterTimeLine
        .add('cursorEnter')
        .to(
          cursor.current,
          { ...cursorSettings, ...styles, attr: { ...attributesCursor } },
          'cursorEnter'
        )
        .to(
          cursorInner.current,
          { ...cursorSettings, attr: { ...attributesCursorRectangle } },
          'cursorEnter'
        )
        .eventCallback('onComplete', () => {
          console.log('Complete cursor enter');
        });
    };

    const enterMouseMove = function (payload) {
      buttonBounds = payload.rect;

      TweenMax.to(cursor.current, { ...styles });
    };

    const leave = function () {
      hover = false;
      window.addEventListener('mousemove', mouseMoveHandler);

      cursorSettings.duration = 0.5;

      const styles = {};

      const attributesCursor = {};
      const attributesCursorRectangle = {};

      cursor.current
        ? (bounds = cursor?.current?.getBoundingClientRect())
        : (bounds = 0);

      // styles.x = mousepos.x - bounds.width / 2;
      // styles.y = mousepos.y - bounds.height / 2;

      styles.width = 12;
      styles.height = 12;

      attributesCursor.width = 12;
      attributesCursor.height = 12;

      attributesCursorRectangle.width = attributesCursor.width - 2;
      attributesCursorRectangle.height = attributesCursor.height - 2;
      attributesCursorRectangle.rx = attributesCursorRectangle.height / 2;
      attributesCursorRectangle['fill-opacity'] = 1;

      if (cursorTimeline) {
        cursorTimeline.kill();
      }

      cursorLeaveTimeLine = TweenMax.timeline();
      cursorLeaveTimeLine
        .add('cursorEnter')
        .to(
          cursor.current,
          { ...cursorSettings, ...styles, attr: { ...attributesCursor } },
          'cursorEnter'
        )
        .to(
          cursorInner.current,
          { ...cursorSettings, attr: { ...attributesCursorRectangle } },
          'cursorEnter'
        )
        .eventCallback('onComplete', () => {
          console.log('Complete cursor leave');
        });
    };

    const animate = function (payload) {
      if (hover) {
        cursor.current.style.transform = `translate(${payload.rect.x}px, ${payload.rect.y}px)`;
      }
    };

    const init = function () {
      bindEvents();
    };

    const terminate = function () {
      unBindEvents();
    };

    init();
    return () => terminate();
  }, []);

  return (
    <AnimatePresence>
      {!isMobile && isVisible && (
        <CursorStyled ref={cursor} width="12px" height="12px">
          <motion.rect
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              default: {
                delay: 0.4,
                duration: 0.5,
                ease: [0, 0.71, 0.2, 1.01],
              },
              scale: {
                type: 'spring',
                damping: 3,
                stiffness: 100,
                restDelta: 0.02,
              },
            }}
            ref={cursorInner}
            stroke="#D41B64"
            strokeWidth="2"
            fill="#D41B64"
            x="1"
            y="1"
            width="10"
            height="10"
            rx="99"
          />
        </CursorStyled>
      )}
    </AnimatePresence>
  );
};

export default Cursor;

const CursorStyled = styled(motion.svg)`
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  pointer-events: none;
  fill: #d41b64; // Primary color
  overflow: visible !important;
  // border-radius: 50%;
  // width: 12px;
  // height: 12px;
`;
