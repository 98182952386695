// Gets the mouse position
const getMousePos = (e) => {
  return { x: e.clientX, y: e.clientY };
};

// Linear interpolation
const lerp = (a, b, n) => (1 - n) * a + n * b;

// Calculate distance
const distance = (x1, y1, x2, y2) => {
  var a = x1 - x2;
  var b = y1 - y2;

  return Math.hypot(a, b);
};

function prepareIdFromName(item) {
  return item.replace(/\./g, '').replace(/ /g, '-').toLowerCase();
}

function formatDate(date) {
  const dateString = date;
  const [day, month, year] = dateString.split('-');
  const newDate = new Date(`${month}/${day}/${year}`);

  const formatter = new Intl.DateTimeFormat('en', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

  const result = formatter.format(newDate);
  return result;
}

function scrollTop(scroll) {
  if (scroll) {
    scroll.scrollTo('top', {
      duration: 600,
      easing: [0.25, 0.0, 0.35, 1.0],
      disableLerp: true,
    });
  }
}

export {
  lerp,
  getMousePos,
  distance,
  prepareIdFromName,
  formatDate,
  scrollTop,
};
