import React, { createContext, useState, useContext } from 'react';

const RouteAnimateContext = createContext();

export const RouteAnimateProvider = ({ children }) => {
  const [isAnimating, setIsAnimating] = useState(false);

  const updateIsAnimatingState = (value) => {
    setIsAnimating(value);
  };

  return (
    <RouteAnimateContext.Provider
      value={{ isAnimating, updateIsAnimatingState }}
    >
      {children}
    </RouteAnimateContext.Provider>
  );
};

export const useRouteAnimate = () => {
  return useContext(RouteAnimateContext);
};
